"use client";
import { faqDataMain } from "@/data/faqs";
import React, { useEffect, useRef, useState } from "react";

export default function FaqWithButton({ data, description }) {
    // const questionRefs = useRef([]);
    // const answerRefs = useRef([]);
    const [currentIndex, setCurrentIndex] = useState(-1);
    useEffect(() => {
        questionRefs.current.forEach((el) => {
            el.classList.remove("active");
        });
        answerRefs.current.forEach((el) => {
            el.style.height = "0px";
            el.style.overflow = "hidden";
            el.style.transition = "all 0.5s ease-in-out";
            el.style.marginBottom = "0px";
        });
        if (currentIndex !== -1) {
            questionRefs.current[currentIndex].classList.add("active");
            const element = answerRefs.current[currentIndex];
            element.style.height = element.scrollHeight + "px";
            element.style.overflow = "hidden";
            element.style.transition = "all 0.5s ease-in-out";
            element.style.marginBottom = "1.55em";
        }
    }, [currentIndex]);

    const questionRefs = useRef([]);
    const answerRefs = useRef([]);

    const toggleAccordions = (i) => {
        if (questionRefs.current[i].classList.contains("active")) {
            questionRefs.current[i].classList.remove("active");
            const element = answerRefs.current[i];
            element.style.height = "0px";
            element.style.overflow = "hidden";
            element.style.transition = "all 0.5s ease-in-out";
            element.style.marginBottom = "0px";
        } else {
            questionRefs.current[i].classList.add("active");
            const element = answerRefs.current[i];
            element.style.height = element.scrollHeight + "px";
            element.style.overflow = "hidden";
            element.style.transition = "all 0.5s ease-in-out";
            element.style.marginBottom = "1.55em";
        }
    };

    return (
        <>
            {data && data.length > 0 ? (

                <section className="page-section z-index-1">
                    <div className="container position-relative">
                        <div className="row position-relative">
                            <div className="col-md-6 col-lg-5 mb-md-50 mb-sm-30">
                                <h2 className="section-title mb-30">
                                    Frequently Asked Questions
                                </h2>
                                <p className="text-gray mb-0">
                                    {description ? description : ' '}
                                </p>
                            </div>
                            <div className="col-md-6 offset-lg-1 pt-10 pt-sm-0">
                                <div>
                                    <div className="call-action-2-text mb-50 mb-sm-40">
                                        <dl className="accordion">
                                            <dl className="toggle">

                                                {data?.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        <dt
                                                            onClick={() => {
                                                                toggleAccordions(index);
                                                            }}
                                                        >
                                                            <a ref={(el) => (questionRefs.current[index] = el)}>
                                                                {item.name}
                                                            </a>
                                                        </dt>
                                                        <dd
                                                            ref={(el) => (answerRefs.current[index] = el)}
                                                            className="black faqAnswer"
                                                            style={{
                                                                height: "0px",
                                                                overflow: "hidden",
                                                                marginBottom: "0px",
                                                                transition: "all 0.5s ease-in-out",
                                                            }}
                                                        >
                                                            {item.acceptedAnswer?.text}
                                                        </dd>
                                                    </React.Fragment>
                                                ))}
                                            </dl>
                                        </dl>
                                    </div >
                                    <div className="local-scroll">

                                        <a
                                            href="/pricing"
                                            className="btn btn-mod btn-grad btn-large btn-round mb-xs-10"
                                            data-btn-animate="y"
                                        >
                                            Start 15 Days Trial
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            ) : (
                <>
                </>
            )}
        </>

    );
}
