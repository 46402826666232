import(/* webpackMode: "eager" */ "/home/runner/work/getbiz_website/getbiz_website/app/api/JsonLd.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/getbiz_website/getbiz_website/components/accordions/FaqWithButton.jsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/getbiz_website/getbiz_website/components/common/ParallaxContainer.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/getbiz_website/getbiz_website/components/gitbiz/procuct/HRMSPrice.jsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/getbiz_website/getbiz_website/components/headers/Header1.jsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/getbiz_website/getbiz_website/components/headers/HeaderPreview2.jsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/getbiz_website/getbiz_website/components/headers/HeaderPreview4.jsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/getbiz_website/getbiz_website/components/headers/HeaderPreview5.jsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/getbiz_website/getbiz_website/components/headers/HeaderPreviewfinal.jsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/getbiz_website/getbiz_website/components/homes/home-1/heros/Hero1.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/getbiz_website/getbiz_website/components/homes/home-1/heros/Hero2.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/getbiz_website/getbiz_website/components/homes/home2/Pricing.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/getbiz_website/getbiz_website/components/homes/home2/Slider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/getbiz_website/getbiz_website/components/homes/home2/Testimonials.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/getbiz_website/getbiz_website/components/others/ClientSlider.jsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/getbiz_website/getbiz_website/components/preview/Testomonials.jsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/getbiz_website/getbiz_website/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/getbiz_website/getbiz_website/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/getbiz_website/getbiz_website/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/getbiz_website/getbiz_website/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
